const { REACT_APP_BACKEND_API_URL, REACT_APP_CAMPAIGN_ID, REACT_APP_IMAGE_RESIZER_HOST } = process.env

const settings = {
  backendUrl: REACT_APP_BACKEND_API_URL || 'http://localhost:4445',
  campaignId: REACT_APP_CAMPAIGN_ID ? parseInt(REACT_APP_CAMPAIGN_ID) : 1,
  imageResizerHost: REACT_APP_IMAGE_RESIZER_HOST || 'http://localhost:4446',
  supportEmail: 'support@fanmadefits.com',
  defaultPageSize: 10
}

export default settings
