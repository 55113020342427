import React from 'react'
import Table from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import type { ColumnsType } from 'antd/es/table'
import { StoreOrderItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-order-item.dto'
import { OrderDetails as OrderDetailsState } from 'features/orders/models'
import styles from './OrderDetailsProducts.module.scss'

type Props = {
  orderDetails: OrderDetailsState
}

const columns: ColumnsType<StoreOrderItemDto> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'productName',
    key: 'productName'
  },
  {
    title: 'Variant',
    dataIndex: 'productVariant',
    key: 'productVariant'
  },
  {
    title: 'SKU',
    dataIndex: 'productSku',
    key: 'productSku'
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (value: number) => `$${(value / 100).toFixed(2)}`
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    key: 'discount',
    render: (value: number) => `$${(value / 100).toFixed(2)}`
  },

  {
    title: 'Supplier Cost',
    dataIndex: 'supplierCost',
    key: 'supplierCost',
    render: (value: number) => `$${(value / 100).toFixed(2)}`
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (value: number) => `$${(value / 100).toFixed(2)}`
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    key: 'profit',
    render: (value: number) => <div className={styles.profit}>${(value / 100).toFixed(2)}</div>
  }
]

const OrderDetailsProducts = ({ orderDetails }: Props) => {
  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        dataSource={orderDetails.orderItems}
        loading={orderDetails.isLoading}
        pagination={false}
        rowKey={(record) => record.id}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Refunded
                    <div className={styles.sumRed}>{`-$${(orderDetails.refunded / 100).toFixed(2)}`}</div>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={4} />
                {/* <Table.Summary.Cell index={2} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Promo Code Discount
                    <div className={styles.sumRed}>-$10.00</div>
                  </div>
                </Table.Summary.Cell> */}
                {/* <Table.Summary.Cell index={4} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Taxes
                    <div className={styles.sum}>$10.00</div>
                  </div>
                </Table.Summary.Cell> */}
                <Table.Summary.Cell index={6} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Subtotal
                    <div className={styles.sum}>{`$${(orderDetails.subtotal / 100).toFixed(2)}`}</div>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Profit Total
                    <div className={styles.sumGreen}>{`$${(orderDetails.totalProfit / 100).toFixed(2)}`}</div>
                  </div>
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={8} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Shipping
                    <div className={styles.sum}>$10.00</div>
                  </div>
                </Table.Summary.Cell> */}
              </Table.Summary.Row>
              {/* <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={6} />
                <Table.Summary.Cell index={6} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Grand Total
                    <div className={styles.sum}>$10.00</div>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} colSpan={2}>
                  <div className={styles.summaryCell}>
                    Profit Total
                    <div className={styles.sumGreen}>$10.00</div>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row> */}
            </>
          )
        }}
      />
    </div>
  )
}

export default observer(OrderDetailsProducts)
