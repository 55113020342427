import React from 'react'
import querystringify from 'querystringify'
import { Button, Form, Input, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { notification } from 'shared'
import { ReactComponent as Logo } from 'assets/logoBlue.svg'
import { user } from 'features/user/models'
import { useLocation, useNavigate } from 'react-router-dom' 
import { AppRoutes } from 'components'
import styles from './ResetPassword.module.scss'

const ResetPassword: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { token: passwordToken } = querystringify.parse(location.search) as any

  const onFinish = async (values: any) => {
    if (values.password === values.repeatPassword) {
      if (await user.resetPassword(values.password, passwordToken)) navigate(AppRoutes.login)
    } else {
      notification({ text: 'Passwords do not match' })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    notification({ text: errorInfo })
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.Title level={1}>Reset password</Typography.Title>
        <Logo className={styles.logo} />
        <Form
          name='reset-password'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          disabled={user.isLoading}
          autoComplete='off'
          size='large'
          layout='vertical'
          className={styles.form}
        >
          {passwordToken && (
            <>
              <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password />
              </Form.Item>

              <Form.Item
                label='Repeat password'
                name='repeatPassword'
                rules={[{ required: true, message: 'Please repeat your password!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit' className={styles.btn}>
                  Confirm
                </Button>
              </Form.Item>
            </>
          )}
          {!passwordToken && 'Invalid token'}
        </Form>
      </div>
    </div>
  )
}

export default observer(ResetPassword)
