import React, { useEffect, useState, useRef } from 'react'
import ReactQuill, { Quill, UnprivilegedEditor } from 'react-quill'
import type { Sources, DeltaStatic } from 'quill'
// @ts-ignore
import quillEmoji from 'quill-emoji'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'
import { notification } from 'shared'

import styles from './QuillEditor.module.scss'

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji
  },
  true
)

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
          '#bbbbbb',
          '#f06666',
          '#ffc266',
          '#ffff66',
          '#66b966',
          '#66a3e0',
          '#c285ff',
          '#888888',
          '#a10000',
          '#b26b00',
          '#b2b200',
          '#006100',
          '#0047b2',
          '#6b24b2',
          '#444444',
          '#5c0000',
          '#663d00',
          '#666600',
          '#003700',
          '#002966',
          '#3d1466',
          'custom-color'
        ]
      },
      { background: [] }
    ],
    ['link', 'emoji'],
    ['clean']
  ],
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true
}

type Props = {
  limit?: number
  value?: string
  disabled?: boolean
  onChange?: (newDescription: string) => void
}

const QuillEditor = ({ limit = 300, value, disabled, onChange = () => ({}) }: Props) => {
  const formats = ['bold', 'italic', 'underline', 'strike', 'color', 'background', 'link', 'clean', 'emoji']

  const reactQuillRef = useRef<ReactQuill>(null)
  const [counter, setCounter] = useState<number>((reactQuillRef?.current?.unprivilegedEditor?.getLength() || 1) - 1 || 0)

  useEffect(() => {
    setCounter((reactQuillRef?.current?.unprivilegedEditor?.getLength() || 1) - 1)
  }, [reactQuillRef?.current?.unprivilegedEditor?.getLength()])

  const handleChange = (content: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => {
    if (limit && editor.getLength() >= limit) {
      notification({
        text: 'Description is too long!',
        type: 'error'
      })
    } else {
      onChange(content)
    }
  }

  return (
    <>
      <ReactQuill
        readOnly={disabled}
        theme='snow'
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={`Max length is ${limit} symbols`}
        style={{ whiteSpace: 'pre' }}
        ref={reactQuillRef}
      />

      {limit && <p className={styles.counter}>{`${limit - counter}  characters left`}</p>}
    </>
  )
}

export default QuillEditor
