enum AppRoutes {
  dashboard = '/dashboard',
  home = '/',
  products = '/products',
  orders = '/orders',
  payments = '/payments',
  login = '/login',
  logout = '/login',
  resetPassword = '/reset-password',
  confirmInvite = '/confirm-invite'
}

export default AppRoutes
