import React, { useState, useEffect } from 'react'
import { Upload } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { CloudUploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import styles from './UploadImage.module.scss'

type Props = {
  aspectRatio: number
  shape?: 'rect' | 'round'
  big?: boolean
  storeFileList?: UploadFile[]
}

const UploadImage = ({ aspectRatio, storeFileList, big = false, shape = 'rect' }: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  useEffect(() => {
    setFileList(storeFileList || [])
  }, [storeFileList])

  const onPreview = async (file: UploadFile) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src as string
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  const uploadBtn = (
    <div className={styles.upload}>
      <div className={styles.firstCircle}>
        <div className={styles.secondCircle}>
          <CloudUploadOutlined style={{ fontSize: 20, color: '#667085 ' }} />
        </div>
      </div>
      <div className={styles.uploadTextSingle}>Click to upload</div>
    </div>
  )

  const uploadBtnBig = (
    <div className={styles.uploadBig}>
      <div className={styles.firstCircle}>
        <div className={styles.secondCircle}>
          <CloudUploadOutlined style={{ fontSize: 20, color: '#667085 ' }} />
        </div>
      </div>
      <div className={styles.textRow}>
        <div className={styles.uploadText}>Click to upload</div>
        <div className={styles.uploadText2}>or drag and drop</div>
      </div>
      <div className={styles.uploadText3}>SVG, PNG, JPG (max. 800x400px)</div>
    </div>
  )

  const button = big ? uploadBtnBig : uploadBtn

  return (
    <ImgCrop aspect={aspectRatio} shape={shape} rotate>
      <Upload
        name='avatar'
        listType='picture-card'
        action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
        onChange={onChange}
        onPreview={onPreview}
        fileList={fileList}
      >
        {(fileList?.length < 1 && button) || null}
      </Upload>
    </ImgCrop>
  )
}

export default UploadImage
