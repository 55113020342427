import React, { useState, useRef, useEffect } from 'react'
import { Button } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Input } from 'components'
import { useHandleClickOutside } from 'helpers'
import facebookIcon from 'assets/facebook.svg'
import instagramIcon from 'assets/instagram.svg'
import tiktokIcon from 'assets/tiktok.svg'
import twitterIcon from 'assets/twitter.svg'
import linkedinIcon from 'assets/linkedin.svg'
import youtubeIcon from 'assets/youtube.svg'
import threadsIcon from 'assets/threads.png'
import pinterestIcon from 'assets/pinterest.svg'

import styles from './SocialItem.module.scss'

export enum SocialNetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Pinterest = 'Pinterest',
  TikTok = 'TikTok',
  YouTube = 'YouTube',
  Threads = 'Threads'
}

type Props = {
  socialNetwork: SocialNetwork
  value?: string
  onChange?: (newValue: string) => void
}

const socialIcons: Record<SocialNetwork, string> = {
  [SocialNetwork.Facebook]: facebookIcon,
  [SocialNetwork.Twitter]: twitterIcon,
  [SocialNetwork.Instagram]: instagramIcon,
  [SocialNetwork.LinkedIn]: linkedinIcon,
  [SocialNetwork.Pinterest]: pinterestIcon,
  [SocialNetwork.TikTok]: tiktokIcon,
  [SocialNetwork.YouTube]: youtubeIcon,
  [SocialNetwork.Threads]: threadsIcon
}
// const socialKey: Record<SocialNetwork, string> = {
//   [SocialNetwork.Facebook]: 'facebookLink',
//   [SocialNetwork.Twitter]: 'twitterLink',
//   [SocialNetwork.Instagram]: 'instagramLink',
//   [SocialNetwork.LinkedIn]: 'linkedInLink',
//   // [SocialNetwork.Pinterest]: 'pinterestLink',
//   [SocialNetwork.TikTok]: 'tiktokLink',
//   [SocialNetwork.YouTube]: 'youtubeLink'
// }

const SocialItem = ({ value = '', socialNetwork, onChange = () => ({}) }: Props) => {
  // const form = Form.useFormInstance()

  const [link, setLink] = useState<string>(value)

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    setLink(value)
  }, [value])

  const clickOutsideRef = useRef(null)
  useHandleClickOutside(clickOutsideRef, () => setEditMode(false))

  let button = (
    <div className={styles.btnBox}>
      <Button type='dashed' onClick={() => setEditMode(true)}>
        Add link
      </Button>
    </div>
  )

  if (link) {
    button = (
      <div className={styles.wrapper}>
        <div className={styles.removeIcon} onClick={() => setLink('')}>
          <DeleteOutlined style={{ fontSize: 17, color: '#667085 ' }} />
        </div>
        <div className={styles.editIcon} onClick={() => setEditMode(true)}>
          <EditOutlined style={{ fontSize: 17, color: '#667085 ' }} />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.socialItem} ref={clickOutsideRef}>
      <div className={styles.iconWrap}>
        <div className={styles.icon}>
          <img
            src={socialIcons[socialNetwork]}
            width={socialNetwork === SocialNetwork.Threads ? 30 : undefined}
            alt={`${socialNetwork} logo`}
          />
        </div>
      </div>
      {!editMode && (
        <div className={styles.socialText} onClick={() => setEditMode(true)}>
          <div className={styles.socialTitle}>{socialNetwork}</div>
          {link ? (
            <div className={styles.socialSubitle}>{link}</div>
          ) : (
            <div className={styles.socialSubitle}>Add your profile link here</div>
          )}
        </div>
      )}
      {editMode && (
        <div className={styles.inputBox}>
          <Input placeholder={`Add ${socialNetwork} Link`} defaultValue={link} onChange={(value: string) => setLink(value)} />
        </div>
      )}

      {editMode ? (
        <div className={styles.btnBox}>
          <Button
            type='dashed'
            onClick={() => {
              setTimeout(() => {
                onChange(link)
                setEditMode(false)
              }, 300)
            }}
          >
            Submit
          </Button>
        </div>
      ) : (
        button
      )}
    </div>
  )
}

export default SocialItem
