import React, { useEffect } from 'react'
import numeral from 'numeral'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'features/storeSettings/models'
import { DollarOutlined, FieldTimeOutlined } from '@ant-design/icons'
import PaymentsList from '../PaymentsList'
import PaymentInfo from '../PaymentInfo'
import styles from './PaymentsPage.module.scss'

const PaymentsPage: React.FC = () => {
  const onChange = (key: string) => {
    console.log(key)
  }

  useEffect(() => {
    store.fetch()
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'History',
      children: <PaymentsList />
    },
    {
      key: '2',
      label: 'Payment info',
      children: <PaymentInfo />
    }
  ]

  const hasPaymentAccount = true

  return (
    <div className={styles.container}>
      <div className={styles.title}>Payments</div>
      {hasPaymentAccount && (
        <div className={styles.statistic}>
          <div className={styles.statisticBox}>
            <div className={styles.statisticTextColumn}>
              <div className={styles.statisticTitle}>Pending Balance</div>
              <div className={styles.statisticSum}>{numeral(store.pendingBalance / 100).format('$ 0,0.00')}</div>
            </div>
            <div className={styles.iconBox}>
              <div className={styles.icon} style={{ fontSize: '15px', color: '#5D4AC5' }}>
                <FieldTimeOutlined />
              </div>
            </div>
          </div>
          <div className={styles.statisticBox}>
            <div className={styles.statisticTextColumn}>
              <div className={styles.statisticTitle}>Available Balance</div>
              <div className={styles.statisticSum}>{numeral(store.balance / 100).format('$ 0,0.00')}</div>
            </div>
            <div className={styles.iconBox}>
              <div className={styles.icon} style={{ fontSize: '15px', color: '#5D4AC5' }}>
                <DollarOutlined />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.tabsBox}>
        <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
      </div>
    </div>
  )
}

export default observer(PaymentsPage)
