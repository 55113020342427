import React from 'react'
import { Button, Form, Input, Modal, Select } from 'antd'
import { CaretDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './PaymentAccountModal.module.scss'

type Props = {
  isVisible: boolean
  onClose: () => void
}

const options = [
  {
    label: 'Individual',
    value: 'individual'
  }
]

const PaymentAccountModal = ({ isVisible, onClose }: Props) => {
  const [form] = Form.useForm()

  const onFinish = (_values: any) => {
    onClose()
  }

  const onFinishFailed = (errors: any) => {
    console.log('finish failed')
  }
  return (
    <Modal title='Add New Bank Account' onCancel={onClose} open={isVisible} width='640px' footer={null}>
      <Form
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        size='large'
        layout='horizontal'
        labelAlign='left'
        form={form}
      >
        <div className={styles.formItemRow}>
          <div className={styles.formItem}>
            <div className={styles.label}>First Name</div>
            <Form.Item name='firstName' rules={[{ required: true, message: 'Please input first name!' }]} style={{ width: '100%' }}>
              <Input placeholder='John' size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
          <div className={styles.formItem}>
            <div className={styles.label}>Last Name</div>
            <Form.Item name='lastName' rules={[{ required: true, message: 'Please input last name!' }]} style={{ width: '100%' }}>
              <Input placeholder='Kahnman' size='large' style={{ width: '100%' }} />
            </Form.Item>
          </div>
        </div>

        <div className={styles.formItemRowSingle}>
          <div className={styles.label}>Account Number *</div>
          <Form.Item name='accountNumber' rules={[{ required: true, message: 'Please input account number!' }]} style={{ width: '100%' }}>
            <Input placeholder='xxxxxxxxxxxx' size='large' style={{ width: '100%' }} />
          </Form.Item>
        </div>

        <div className={styles.formItemRowSingle}>
          <div className={styles.label}>Routing Number *</div>
          <Form.Item name='routingNumber' rules={[{ required: true, message: 'Please input routing number!' }]} style={{ width: '100%' }}>
            <Input placeholder='xxxxxxxxxxxx' size='large' style={{ width: '100%' }} />
          </Form.Item>
        </div>

        <div className={styles.formItemRowSingle}>
          <div className={styles.label}>Account Holder Type *</div>
          <Form.Item
            name='accountHolderType'
            rules={[{ required: true, message: 'Please select account holder type!' }]}
            style={{ width: '100%' }}
          >
            <Select
              defaultActiveFirstOption
              options={options}
              size='large'
              suffixIcon={<CaretDownOutlined style={{ color: '#667085', fontSize: '16px' }} />}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
        <div className={styles.info}>
          <ExclamationCircleOutlined style={{ fontSize: '16px', color: '#667085' }} />
          <div className={styles.infoText}>
            Note: this bank account will be added only after you complete Stripe’s verification process through micro-deposit.
          </div>
        </div>
        <div className={styles.btnGroup}>
          <div className={styles.btn} onClick={onClose}>
            <Button size='large'>Cancel</Button>
          </div>
          <div className={styles.btn} onClick={onClose}>
            <Button size='large' type='primary'>
              Confirm
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default PaymentAccountModal
