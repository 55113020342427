import { CustomerSessionV2Dto } from '@merchx-v2/shared-types/dist/dto/auth/customer-session-v2.dto'
import { AuthorizedCustomerDto } from '@merchx-v2/shared-types/dist/dto/auth/authorized-customer.dto'
import { CustomerMembershipType } from '@merchx-v2/shared-types/dist/constants/customerMembershipType'
import { makeAutoObservable } from 'mobx'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import axios from 'axios'
import api from 'services/api'
import { store } from 'features/storeSettings/models'
import { products } from 'features/products/models'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'
import { PublicStoresPageDto, PublicStoresPageItemDto } from '@merchx-v2/shared-types'

type CustomerLoginQueryResponse<Result extends string = 'customerAccountLogin'> = GraphQLResponse<Result, CustomerSessionV2Dto>
type CustomerLogoutQueryResponse<Result extends string = 'customerAccountLogout'> = GraphQLResponse<Result, boolean>
type CustomerAccountQueryResponse<Result extends string = 'customerAccount'> = GraphQLResponse<Result, AuthorizedCustomerDto>
type CustomerResetPasswordQueryResponse<Result extends string = 'customerAccountResetPassword'> = GraphQLResponse<Result, boolean>
type CustomerAccountStoresResponse<Result extends string = 'customerStores'> = GraphQLResponse<Result, PublicStoresPageDto>

const CUSTOMER_ACCOUNT_LOGIN = `
mutation CustomerAccountLogin($campaignId: Int!, $email: String!, $password: String!, $rememberMe: Boolean) {
  customerAccountLogin(campaignId: $campaignId, email: $email, password: $password, rememberMe: $rememberMe) {
    accessToken
    refreshToken
    expiresIn
    user {
      id
      firstName
      lastName
      username
      email
      imageUrl
      role
      membershipRole
    }
  }
}
`

const CUSTOMER_ACCOUNT = `
query CustomerAccount {
  customerAccount {
    id
    firstName
    lastName
    email
    imageUrl
    username
    phone
    facebookLink
    twitterLink
    linkedInLink
    instagramLink
  }
}
`

const CUSTOMER_ACCOUNT_LOGOUT = `
mutation CustomerAccountLogout($logoutFromAllDevices: Boolean) {
  customerAccountLogout(logoutFromAllDevices: $logoutFromAllDevices)
}
`

const RESET_PASSWORD = `
mutation CustomerAccountResetPassword ($newPassword: String!, $token: String!) {
  customerAccountResetPassword(newPassword: $newPassword, token: $token)
}
`

const CUSTOMER_STORES = `
query CustomerStores {
  customerStores {
    items {
      id
      name
      seoUrl
      avatarImageUrl
      mainImageUrl
    }
    total
  }
}
`

export class User {
  id = -1
  firstName = ''
  lastName = ''
  email = ''
  username = ''
  imageUrl = ''
  role = ''
  membershipRole: CustomerMembershipType | null = null
  customerStores: PublicStoresPageItemDto[] = []
  status: RequestStatus = 'idle'
  error = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    this.fetch()
  }

  *login(email: string, password: string, rememberMe?: boolean) {
    if (this.isLoading) return false
    this.status = 'loading'

    try {
      const { data }: CustomerLoginQueryResponse = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: CUSTOMER_ACCOUNT_LOGIN,
        variables: { email, password, rememberMe, campaignId: settings.campaignId }
      })

      guardFromErrors(data?.errors)

      if (!data?.data) {
        throw new Error('Nwtwork error!')
      }

      const { customerAccountLogin } = data.data

      if (rememberMe) {
        localStorage.setItem('token', customerAccountLogin.accessToken)
        localStorage.setItem('refreshToken', customerAccountLogin.refreshToken)
      } else {
        sessionStorage.setItem('token', customerAccountLogin.accessToken)
        sessionStorage.setItem('refreshToken', customerAccountLogin.refreshToken)
      }

      this.id = customerAccountLogin.user.id
      this.email = customerAccountLogin.user.email
      this.firstName = customerAccountLogin.user.firstName
      this.lastName = customerAccountLogin.user.lastName
      this.username = customerAccountLogin.user.username ?? ''
      this.imageUrl = customerAccountLogin.user.imageUrl ?? ''
      this.role = customerAccountLogin.user.role
      this.membershipRole = customerAccountLogin.user.membershipRole

      this.status = 'success'
      this.error = ''

      return !!customerAccountLogin.user.id
    } catch (err) {
      this.status = 'error'
      this.error = extractErrorInfo(err)
      this.clearUser()
      notification({ text: extractErrorInfo(err) })
      return false
    }
  }

  *fetch() {
    if (this.status !== 'idle' || !this.hasTokens()) {
      this.status = 'error'
      return false
    }

    this.status = 'loading'

    try {
      const response: CustomerAccountQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: CUSTOMER_ACCOUNT
      })

      if (!response?.data) throw new Error()

      const { data, errors } = response.data
      guardFromErrors(errors)

      if (!data?.customerAccount) throw new Error()

      const { customerAccount } = data

      this.id = customerAccount.id
      this.email = customerAccount.email
      this.firstName = customerAccount.firstName
      this.lastName = customerAccount.lastName
      this.username = customerAccount.username ?? ''
      this.imageUrl = customerAccount.imageUrl ?? ''

      this.status = 'success'
      this.error = ''

      return true
    } catch (err) {
      this.status = 'error'
      return false
    }
  }

  *fetchUserStores() {
    if (!this.isLoggedIn) {
      this.status = 'error'
      return false
    }

    // this.status = 'loading'

    try {
      const response: CustomerAccountStoresResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: CUSTOMER_STORES
      })

      if (!response?.data) throw new Error()

      const { data, errors } = response.data
      guardFromErrors(errors)

      if (!data?.customerStores) throw new Error()

      this.customerStores = data.customerStores.items

      this.status = 'success'
      this.error = ''

      return true
    } catch (err) {
      this.status = 'error'
      return false
    }
  }

  *logout() {
    if (this.isLoading) return
    this.status = 'loading'

    this.clearUser()
    products.reset()
    store.reset()

    try {
      if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
        this.clearTokens()

        const response: CustomerLogoutQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
          query: CUSTOMER_ACCOUNT_LOGOUT
        })

        if (!response) throw new Error()
        const { errors } = response.data

        guardFromErrors(errors)
      }

      this.status = 'success'
      this.error = ''

      return true
    } catch {
      this.status = 'error'
      this.clearUser()
    }
  }

  *resetPassword(password: string, token: string) {
    if (this.isLoading) return
    this.status = 'loading'

    try {
      const { data }: CustomerResetPasswordQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: RESET_PASSWORD,
        variables: { newPassword: password, token }
      })

      guardFromErrors(data?.errors)

      if (!data?.data) {
        throw new Error('Network error!')
      }

      const { customerAccountResetPassword } = data.data

      this.status = 'success'
      this.error = ''
      return customerAccountResetPassword
    } catch (err) {
      this.status = 'error'
      this.error = extractErrorInfo(err)
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.status === 'loading'
  }

  get isLoggedIn() {
    return this.id !== -1 && this.status === 'success'
  }

  reset() {
    this.clearUser()
    this.clearTokens()
    this.status = 'idle'
    this.error = ''
  }

  clearUser() {
    this.id = -1
    this.firstName = ''
    this.lastName = ''
    this.email = ''
    this.username = ''
    this.imageUrl = ''
    this.role = ''
    this.membershipRole = null
  }

  private clearTokens() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('refreshToken')
  }

  private hasTokens() {
    return (
      localStorage.getItem('token') ||
      localStorage.getItem('refreshToken') ||
      sessionStorage.getItem('token') ||
      sessionStorage.getItem('refreshToken')
    )
  }
}

export const user = new User()
