import React from 'react'
import { Button, Checkbox, Form, Input, Typography } from 'antd'
import { observer } from 'mobx-react-lite'
import { notification } from 'shared'
import { ReactComponent as Logo } from 'assets/logoBlue.svg'
import { user } from 'features/user/models'
import styles from './Login.module.scss'

const Login: React.FC = () => {
  const onFinish = async (values: any) => {
    user.login(values.email, values.password, values.remember)
  }

  const onFinishFailed = (errorInfo: any) => {
    notification({ text: errorInfo })
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.Title level={1}>Log In</Typography.Title>
        <Logo />
        <Form
          name='login'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          disabled={user.isLoading}
          autoComplete='off'
          size='large'
          layout='vertical'
          className={styles.form}
        >
          <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item className={styles.rememberMe} name='remember' valuePropName='checked'>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className={styles.submitButton}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default observer(Login)
