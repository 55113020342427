import React, { useMemo } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import styles from './Pagination.module.css'

type Props = {
  currentPage: number
  totalPages: number
  maxRangeButtons?: number
  onChange?: (page: number) => void
}

enum Directions {
  Prev = 'prev',
  Next = 'next'
}

const getPagesRange = (totalPages: number, currentPage: number, maxRangeButtons: number): number[] => {
  const result = []
  const leftOffsetFromCurrentPage = Math.floor(maxRangeButtons / 2)
  const maxVisibleItems = Math.min(maxRangeButtons, totalPages)

  for (let index = 0; index < maxVisibleItems; index++) {
    result.push(index + Math.min(totalPages - maxVisibleItems + 1, Math.max(currentPage - leftOffsetFromCurrentPage, 1)))
  }

  return result
}

const Pagination = (props: Props) => {
  const { totalPages, currentPage, maxRangeButtons = 7, onChange } = props
  const isHasPrevPage = currentPage > 1
  const isHasNextPage = currentPage < totalPages

  const pageButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onChange?.(Number(event.currentTarget.dataset.value))
  }

  const navButtonClickHamdler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const { direction } = event.currentTarget.dataset
    direction === Directions.Prev && isHasPrevPage && onChange && onChange(currentPage - 1)
    direction === Directions.Next && isHasNextPage && onChange && onChange(currentPage + 1)
  }

  const renderPagesButtons = () => {
    const isSkipPagesOnLeft = totalPages > maxRangeButtons && currentPage - 1 > Math.floor(maxRangeButtons / 2)
    const isSkipPagesOnRight = totalPages > maxRangeButtons && currentPage < totalPages - Math.floor(maxRangeButtons / 2)

    const buttons = getPagesRange(totalPages, currentPage, maxRangeButtons).map((pageNumber, index) => {
      if ((isSkipPagesOnLeft && index === 1) || (isSkipPagesOnRight && index === maxRangeButtons - 2)) {
        return (
          <div key={pageNumber} className={styles.infoItemDots}>
            ...
            {/* <Typography text='...' size={16} lineHeight='140%' weight={600} color='var(--gray-text-color)' /> */}
          </div>
        )
      }

      pageNumber =
        (isSkipPagesOnLeft && index === 0 && 1) || (isSkipPagesOnRight && index === maxRangeButtons - 1 && totalPages) || pageNumber

      return (
        <button
          key={pageNumber}
          className={styles.infoItem}
          onClick={pageButtonClickHandler}
          data-value={pageNumber}
          data-is-active={pageNumber === currentPage}
        >
          <div className={styles.btnText}>{pageNumber}</div>
        </button>
      )
    })

    return buttons
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pagesButtons = useMemo(renderPagesButtons, [currentPage, totalPages])

  return (
    <div className={styles.container}>
      <button className={styles.bigBtn} data-direction={Directions.Prev} data-is-disabled={!isHasPrevPage} onClick={navButtonClickHamdler}>
        <div className={styles.icon}>
          <ArrowLeftOutlined />
        </div>
        <div className={styles.prev}>Previous</div>
      </button>
      <div className={styles.desktopInfo}>{pagesButtons}</div>

      <button className={styles.bigBtn} data-direction={Directions.Next} data-is-disabled={!isHasNextPage} onClick={navButtonClickHamdler}>
        <div className={styles.next}>Next</div>
        <div className={styles.icon}>
          <ArrowRightOutlined />
        </div>
      </button>
    </div>
  )
}

export default Pagination
