import React from 'react'
import { Modal } from 'antd'
import storeCoverImg from 'assets/storeCover.png'
import styles from './StoreCoverModal.module.scss'

type Props = {
  isVisible: boolean
  onClose: () => void
}

const StoreCoverModal = ({ isVisible, onClose }: Props) => {
  return (
    <Modal title='Cover Photo: How it works' open={isVisible} footer={null} width={572} onCancel={onClose}>
      <div className={styles.container}>
        <img src={storeCoverImg} alt='Cover image place' className={styles.logo} />
        <div className={styles.textSection}>
          <p className={styles.text}>
            A <span className={styles.textStrong}>cover</span> for your store page will help to personalize the brand. These cover images
            are extremely important and can make a world of difference when it comes to who follows, likes and shares your products and
            profiles.
          </p>
          <br /> This image will be displayed on your Store Page.
        </div>
      </div>
    </Modal>
  )
}

export default StoreCoverModal
