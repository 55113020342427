import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DatePicker, Table, Tag } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { OrderStatus } from '@merchx-v2/shared-types/dist/constants/orderStatus'
import { StoreOrdersPageItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-orders-page-item.dto'

import { settings } from 'shared'
import { transformUppercase } from 'helpers'
import { Pagination } from 'components'
import OrderDetails from '../OrderDetails'

import { store } from 'features/storeSettings/models'
import { orderDetails, ordersList } from 'features/orders/models'
import styles from './OrdersList.module.scss'

type PresetItem = {
  label: string
  value: [Dayjs, Dayjs]
}

const presets: PresetItem[] = [
  { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
  { label: 'Yesterday', value: [dayjs().add(-1, 'day').startOf('day'), dayjs().add(-1, 'd').endOf('day')] },
  { label: 'This week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
  { label: 'Last week', value: [dayjs().add(-1, 'week').startOf('week'), dayjs().add(-1, 'week').endOf('week')] },
  { label: 'This month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
  { label: 'Last month', value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')] },
  { label: 'This year', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
  { label: 'Last year', value: [dayjs().add(-1, 'year').startOf('year'), dayjs().add(-1, 'year').endOf('year')] }
]

const tagColors: Record<OrderStatus, string> = {
  PENDING: 'yellow',
  HOLD: 'yellow',
  NEW: 'green',
  OPENED: 'green',
  DISPATCHED: 'green',
  SHIPPING: 'green',
  COMPLETED: 'green',
  FAILED: 'red',
  CANCELED: 'red',
  CANCELLING: 'red'
}

const OrdersPage: React.FC = () => {
  const [page, setPage] = useState<number>(1)
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'))
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'))
  const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false)

  useEffect(() => {
    store.fetch()
  }, [])

  useEffect(() => {
    if (store.status === 'success') {
      ordersList.fetch(store.id, startDate?.toDate(), endDate?.toDate(), page)
    }
  }, [store.id, store.status, page, startDate, endDate])

  const columns: ColumnsType<StoreOrdersPageItemDto> = [
    {
      title: <div className={styles.grayText}>ID</div>,
      dataIndex: 'id',
      width: '93px'
    },
    {
      title: <div className={styles.grayText}>Date</div>,
      dataIndex: 'date',
      width: '189px',
      render: (value: Date) => <div className={styles.darkText}>{dayjs(value).format('MM/DD/YYYY HH:mm:ss')}</div>
    },
    {
      title: <div className={styles.grayText}>Status</div>,
      dataIndex: 'status',
      width: '164px',
      render: (value: OrderStatus) => (
        <Tag color={tagColors[value]}>
          <span className={styles.dot} data-color={tagColors[value]} />
          {transformUppercase(value)}
        </Tag>
      )
    },
    {
      title: <div className={styles.grayText}>Customer</div>,
      dataIndex: ['customerFirstName', 'customerLastName'],
      width: '308px',
      render: (text, record) => <div className={styles.grayText}>{`${record.customerFirstName} ${record.customerLastName}`}</div>
    },
    {
      title: <div className={styles.grayText}>Total Revenue</div>,
      dataIndex: 'totalRevenue',
      width: '154px',
      render: (value: number) => <div className={styles.darkText}>${(value / 100).toFixed(2)}</div>
    },

    {
      title: <div className={styles.grayText}>Profit</div>,
      dataIndex: 'totalProfit',
      key: 'totalProfit',
      width: '154px',
      render: (value: number) => <div className={styles.profit}>${(value / 100).toFixed(2)}</div>
    },
    {
      title: '',
      key: 'viewDetails',
      width: '131px',
      align: 'right',
      render: (text, record) => (
        <div
          className={styles.view}
          onClick={async () => {
            orderDetails.fetch(store.id, record.id)
            setIsDetailsVisible(true)
          }}
        >
          View details
        </div>
      )
    }
  ]

  const onRangeChange = (dates: null | Array<Dayjs | null>) => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (dates && dates?.[0] !== null && dates[1] !== null) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.textColumn}>
          <div className={styles.title}>Orders</div>
          <div className={styles.subtitle}>Manage all orders for store here</div>
        </div>
        <div className={styles.btnGroup}>
          <DatePicker.RangePicker format='MMM D, YYYY' presets={presets} onChange={onRangeChange} defaultValue={presets[0].value} />
          <div className={styles.btn}>
            <Button
              type='primary'
              size='large'
              onClick={() => ordersList.fetchCSV(store.id, startDate?.toDate(), endDate?.toDate())}
              loading={ordersList.isLoading}
              icon={<DownloadOutlined style={{ fontSize: 20 }} />}
            >
              Export CSV
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.table}>
        <Table
          columns={columns}
          dataSource={ordersList.list}
          loading={store.isLoading || ordersList.isLoading}
          pagination={false}
          rowKey={(record) => record.id}
        />
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(ordersList.total / settings.defaultPageSize)}
          onChange={(newPage) => {
            setPage(newPage)
          }}
        />
      </div>
      <OrderDetails isVisible={isDetailsVisible} onClose={() => setIsDetailsVisible(false)} orderDetails={orderDetails} />
    </div>
  )
}

export default observer(OrdersPage)
