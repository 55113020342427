import React from 'react'
import querystringify from 'querystringify'
import { Navigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Spinner from '../Spinner'
import Layout from '../Layout'
import { user } from 'features/user/models'
import AppRoutes from './AppRoutes'

const PrivateRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation()

  if (user.isLoggedIn) {
    return <Layout>{children}</Layout>
  }

  if (user.isLoading) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    )
  }

  if (!user.isLoggedIn && user.status !== 'idle') {
    if (location.pathname === AppRoutes.home) {
      return <Navigate to={AppRoutes.login} />
    } else {
      const callbackUrl = querystringify.stringify({ callbackUrl: location.pathname + location.search })
      // @typescript-eslint/restrict-template-expressions off
      return <Navigate to={`${AppRoutes.login}?${callbackUrl}`} />
    }
  }

  return null
}

export default observer(PrivateRoute)
