import settings from '../shared/settings'
import axios, { AxiosRequestConfig } from 'axios'

const axiosInstance = axios.create({ baseURL: settings.backendUrl })

const REFRESH_TOKEN = `
  mutation CustomerAccountRefreshToken($refreshToken: String!) {
    customerAccountRefreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`

export const updateToken = async (res: any) => {
  try {
    const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
    if (!refreshToken) {
      return
    }

    const response = await axios.post(`${settings.backendUrl}/graphql`, {
      query: REFRESH_TOKEN,
      variables: {
        refreshToken
      }
    })

    const { data, errors } = response.data
    if (errors || !data) {
      return
    }

    const newData = data.customerAccountRefreshToken
    if (!newData) {
      return
    }

    const { accessToken: newToken, refreshToken: newRefreshToken } = newData

    if (!newToken || !newRefreshToken) {
      return
    }

    if (localStorage.getItem('token') || localStorage.getItem('refreshToken')) {
      localStorage.setItem('token', newToken)
      localStorage.setItem('refreshToken', refreshToken)
    }

    if (sessionStorage.getItem('token') || sessionStorage.getItem('refreshToken')) {
      sessionStorage.setItem('token', newRefreshToken)
      sessionStorage.setItem('refreshToken', refreshToken)
    }

    res.config.headers = {
      ...res.config.headers,
      authorization: `Bearer ${newToken as string}`
    }

    const resilt = await axiosInstance(res.config)
    return resilt
  } catch {}
}

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')

  if (token) {
    config.headers = {
      ...config.headers,
      authorization: `Bearer ${token}`
    }
  }

  return config
})

axiosInstance.interceptors.response.use(
  async (res: any): Promise<any> => {
    if (res.data.errors?.find((error: any): any => error.message === "You don't have needed access rights!")) {
      throw new Error()
    }

    if (res.data.errors?.find((error: any): any => error.message === 'Unauthorized!')) {
      const response = await updateToken(res)
      if (!response) {
        throw new Error()
      }

      return response
    }
    return res
  },
  
  async (error: any): Promise<any> => {
    if (error.response?.status === 401) {
      const response = await updateToken(error.response)
      if (!response) {
        throw new Error()
      }
    }

    return error
  }
)

export default axiosInstance
