import React, { ReactNode, useEffect, useState } from 'react'
import { Input, InputProps } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { useDebounce } from 'helpers'

import styles from './Input.module.scss'

type PropsType = {
  placeholder?: string
  type?: InputProps['type']
  size?: 'small' | 'middle' | 'large'
  disabled?: boolean
  label?: string
  errorMessage?: string
  isShowErrors?: boolean
  required?: boolean
  autofillName?: string
  autofillAutocomplete?: string
  bordered?: boolean
  defaultValue?: string
  customDebounceValue?: number
  isUppercasedInput?: boolean
  icon?: ReactNode
  allowClear?: boolean | { clearIcon?: ReactNode }
  onChange?: (value: string) => void
}

const InputComponent = (props: PropsType) => {
  const {
    autofillAutocomplete,
    autofillName,
    type,
    disabled,
    errorMessage,
    isShowErrors,
    label,
    size = 'large',
    placeholder,
    bordered = true,
    defaultValue = '',
    customDebounceValue = 500,
    isUppercasedInput = false,
    icon,
    allowClear = false,

    onChange
  } = props

  const [text, setText] = useState<string>(defaultValue)
  const debouncedText = useDebounce(text, customDebounceValue)

  useEffect(() => {
    onChange?.(debouncedText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText])

  useEffect(() => {
    setText(defaultValue)
  }, [defaultValue])

  const className = bordered ? 'styles.inputField' : 'styles.inputFieldWithoutBorder'
  const clear = allowClear

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      {type === 'password' ? (
        <Input.Password
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          name={autofillName}
          autoComplete={autofillAutocomplete}
          bordered={bordered}
          size={size}
          value={text}
          onChange={(e) => setText(e.target.value)}
          type={type}
          prefix={icon}
          iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
        />
      ) : (
        <Input
          className={className}
          placeholder={placeholder}
          disabled={disabled}
          name={autofillName}
          autoComplete={autofillAutocomplete}
          bordered={bordered}
          size={size}
          value={text}
          onChange={(e) => (isUppercasedInput ? setText(e.target.value.toUpperCase()) : setText(e.target.value))}
          type={type}
          prefix={icon}
          allowClear={clear}
        />
      )}

      {errorMessage && isShowErrors && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}

export default InputComponent
