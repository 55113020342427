import { makeAutoObservable } from 'mobx'
import { StorePayoutsPageDto } from '@merchx-v2/shared-types'
import { StorePayoutDto } from '@merchx-v2/shared-types/dist/dto/stores/store-payout.dto'
import axios from 'services/api'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'

type QueryResponse<Result extends string = 'storePayoutsPage'> = GraphQLResponse<Result, StorePayoutsPageDto>
type QueryResponseCSV<Result extends string = 'storePayoutsPageToCSV'> = GraphQLResponse<Result, string>

const STORE_PAYMENTS = `
query storePayoutsPage ($page: Int, $size: Int, $storeId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  storePayoutsPage (page: $page, size: $size, storeId: $storeId, startDate: $startDate, endDate: $endDate) {
    items {
      id
      storeId
      sum
      paymentMethod
      periodStart
      periodEnd
      processedAt
    }
    total
  }
}
`

const STORE_PAYMENTS_CSV = `
query storePayoutsPageToCSV ($storeId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  storePayoutsPageToCSV (storeId: $storeId, startDate: $startDate, endDate: $endDate)
}
`

class Payments {
  total = 0
  list: StorePayoutDto[] = []
  status: RequestStatus = 'idle'
  error = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.list = []
    this.total = 0
    this.status = 'idle'
    this.error = ''
  }

  *fetchPayments(storeId: number, startDate: Date, endDate: Date, page?: number) {
    if (this.status === 'loading') return
    this.status = 'loading'

    try {
      const { data }: QueryResponse = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_PAYMENTS,
        variables: {
          storeId,
          page: page || 1,
          size: settings.defaultPageSize,
          startDate,
          endDate
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storePayoutsPage) {
        throw new Error("Can't fetch store payments")
      }

      this.total = data.data.storePayoutsPage.total
      this.list = data.data.storePayoutsPage?.items || []

      this.status = 'success'
      this.error = ''
    } catch (err) {
      this.status = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  *fetchCSV(storeId: number, startDate: Date, endDate: Date) {
    if (this.status === 'loading') return
    this.status = 'loading'

    try {
      const { data }: QueryResponseCSV = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_PAYMENTS_CSV,
        variables: {
          storeId,
          startDate,
          endDate
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storePayoutsPageToCSV) {
        throw new Error("Can't fetch store payments csv")
      }

      window.open(data.data.storePayoutsPageToCSV, '_blank')

      this.status = 'success'
      this.error = ''
    } catch (err) {
      this.status = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.status === 'loading'
  }
}

export const payments = new Payments()
