import React, { useEffect } from 'react'
import querystringify from 'querystringify'
import { Avatar, Button, Typography, Form, Input } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { AppRoutes, Spinner } from 'components'
import { notification } from 'shared'
import { user, invite } from 'features/user/models'
import styles from './ConfirmInvite.module.scss'

const ConfirmInvite: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { token } = querystringify.parse(location.search) as any

  const handleYesClick = async () => {
    if (await invite.existUserConfirm(token)) navigate(AppRoutes.home)
  }

  const handleInviteNonExistUser = async (values: any) => {
    if (values.password === values.repeatPassword) {
      if (await invite.nonExistUserConfirm(token, values.password)) navigate(AppRoutes.login)
    } else {
      notification({ text: 'Passwords do not match' })
    }
  }

  const handleNoClick = () => {
    invite.decline(token)
    navigate(AppRoutes.home)
  }

  useEffect(() => {
    token && invite.fetch(token)
  }, [])

  useEffect(() => {
    if (invite.email) {
      form.setFieldsValue({ email: invite.email })
    }
  }, [invite.email])

  useEffect(() => {
    invite.status === 'error' && navigate(AppRoutes.home)
  }, [invite.status])

  if (invite.inviteStatus === 'TOKEN_HAS_EXPIRED') {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography>The link to access this page has expired. </Typography>
          <Typography>Please contact your administrator.</Typography>
        </div>
      </div>
    )
  }

  if (user.isLoggedIn && invite.status === 'success' && user.email !== invite.email) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.avatar}>
            {user.imageUrl ? <Avatar src={user.imageUrl} size={64} /> : <Avatar size={64} icon={<UserOutlined />} />}
          </div>
          <Typography.Title level={3}>{`Hello, ${user.username || user.firstName}`}</Typography.Title>
          <Typography>You are logged in a different account.</Typography>
          <Typography>You need logout first for confirm this invite.</Typography>
          <Button className={styles.logoutButton} onClick={user.logout} type='primary' loading={user.isLoading} disabled={user.isLoading}>
            Logout
          </Button>
        </div>
      </div>
    )
  }

  if (invite.inviteStatus === 'USER_DOES_NOT_EXIST') {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography>You was invited to manage {invite.storeName} store</Typography>
          <Typography>Do you want to accept invite?</Typography>
          <Form
            name='reset-password'
            form={form}
            onFinish={handleInviteNonExistUser}
            disabled={invite.isLoading}
            autoComplete='off'
            size='large'
            layout='vertical'
            className={styles.form}
          >
            <Form.Item label='Email' name='email'>
              <Input disabled />
            </Form.Item>

            <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item label='Repeat password' name='repeatPassword' rules={[{ required: true, message: 'Please repeat your password!' }]}>
              <Input.Password />
            </Form.Item>

            <div className={styles.btnGroup}>
              <Button type='primary' htmlType='submit' className={styles.btn}>
                Accept
              </Button>
              <Button size='large' onClick={handleNoClick}>
                Decline
              </Button>
            </div>
          </Form>
        </div>
      </div>
    )
  }

  if (invite.inviteStatus === 'USER_EXISTS') {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          {user.isLoggedIn && (
            <>
              <div className={styles.avatar}>
                {user.imageUrl ? <Avatar src={user.imageUrl} size={64} /> : <Avatar size={64} icon={<UserOutlined />} />}
              </div>
              <Typography.Title level={3}>{`Hello, ${user.username || user.firstName}`}</Typography.Title>
            </>
          )}
          <Typography>You was invited to manage {invite.storeName} store</Typography>
          <Typography>Do you want to accept invite?</Typography>
          <div className={styles.btnGroup}>
            <Button type='primary' size='large' onClick={handleYesClick}>
              Yes
            </Button>
            <Button size='large' onClick={handleNoClick}>
              No
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return <Spinner />
}

export default observer(ConfirmInvite)
