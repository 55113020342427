import React from 'react'
import clsx from 'clsx'
import styles from './Spinner.module.scss'

type Props = {
  className?: string
}

const Spinner: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(styles.spinner, className)}>
      <div />
    </div>
  )
}

export default Spinner
