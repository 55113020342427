import React, { useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Avatar, Menu, MenuProps } from 'antd'
import {
  AppstoreOutlined,
  BarChartOutlined,
  DollarCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
  SkinOutlined,
  UserOutlined
} from '@ant-design/icons'
import { useHandleClickOutside } from 'helpers'
import { AppRoutes } from 'components'
import { user } from 'features/user/models/user'
import { store } from 'features/storeSettings/models/store'
import { ReactComponent as Logo } from 'assets/logo.svg'
import dropdownIcon from 'assets/dropdown.svg'
import styles from './SiderMenu.module.scss'

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode): MenuItem => {
  return {
    label,
    key,
    icon
  }
}

const iconStyle = { fontSize: 20, color: 'rgba(255, 255, 255, 0.85)' }

const items: MenuItem[] = [
  getItem('Dashboard', AppRoutes.dashboard, <AppstoreOutlined style={iconStyle} />),
  getItem('Products', AppRoutes.products, <SkinOutlined style={iconStyle} />),
  getItem('Orders', AppRoutes.orders, <BarChartOutlined style={iconStyle} />),
  getItem('Payments', AppRoutes.payments, <DollarCircleOutlined style={iconStyle} />),
  getItem('Settings', AppRoutes.home, <SettingOutlined style={iconStyle} />)
]

const SiderMenu: React.FC = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false)

  const clickOutsideRef = useRef(null)
  useHandleClickOutside(clickOutsideRef, () => setIsDropdownVisible(false))

  const navigate = useNavigate()
  const location = useLocation()

  const handleMenuItemClick: MenuProps['onClick'] = ({ key }) => {
    navigate(key)
  }

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.userSection}>
          <div className={styles.avatar}>
            <Avatar
              size={44}
              shape='square'
              src={store.avatarImageUrl !== '' && store.avatarImageUrl}
              icon={store.avatarImageUrl === '' && <UserOutlined />}
            />
          </div>
          <div className={styles.userInfo}>
            <div className={styles.textWrapper}>
              <div className={styles.email}>{store.name}</div>
            </div>

            <div className={styles.iconsBox}>
              <img
                src={dropdownIcon}
                alt='Dropdown'
                width={18}
                height={18}
                onClick={() => {
                  setIsDropdownVisible(!isDropdownVisible)
                  user.fetchUserStores()
                }}
                className={styles.dropdownIcon}
              />
              {isDropdownVisible && user.customerStores.length > 0 && (
                <div className={styles.dropdownBox} ref={clickOutsideRef}>
                  <div className={styles.dropdownBoxTitle}>{user.customerStores.length > 1 ? 'Select store' : 'Selected store'}</div>
                  {user.customerStores.map((storeItem) => (
                    <div
                      key={storeItem.id}
                      className={styles.boxItem}
                      onClick={() => {
                        setIsDropdownVisible(false)
                        store.setActiveStoreId(storeItem.id)
                      }}
                    >
                      <div className={styles.avatar}>
                        <Avatar
                          size={32}
                          shape='square'
                          src={storeItem.avatarImageUrl !== '' && storeItem.avatarImageUrl}
                          icon={storeItem.avatarImageUrl === '' && <UserOutlined />}
                        />
                      </div>
                      <div className={styles.textWrapper}>
                        <div className={styles.username}>{storeItem.name}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <Menu selectedKeys={[location.pathname]} mode='inline' theme='dark' items={items} onClick={handleMenuItemClick} />
      </div>

      <Logo className={styles.logo} />
      <div className={styles.logoutBox}>
        <div className={styles.textWrapper}>
          <div className={styles.logoutBoxEmail}>{user.email}</div>
        </div>

        <div className={styles.logout} onClick={() => user.logout()}>
          <LogoutOutlined style={{ color: 'rgba(255, 255, 255, 0.85)' }} />
        </div>
      </div>
    </div>
  )
}

export default observer(SiderMenu)
