import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import RelativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import { Avatar, Button } from 'antd'
import { ArrowRightOutlined, FundOutlined, MessageOutlined, ShoppingCartOutlined, ShoppingOutlined } from '@ant-design/icons'
import { imageLoader } from 'helpers/image-loader'
import settings from 'shared/settings'
import { dashboard } from '../models'
import { store } from 'features/storeSettings/models/store'
import { user } from 'features/user/models/user'
import { orderDetails } from 'features/orders/models'
import OrderDetails from 'features/orders/components/OrderDetails'
import emptyImg from 'assets/empty.svg'

import styles from './Dashboard.module.scss'

const Dashboard: React.FC = () => {
  const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false)

  const navigate = useNavigate()

  dayjs.extend(RelativeTime)

  useEffect(() => {
    if (store.activeStoreId) {
      dashboard.fetch(store.activeStoreId)
    }
  }, [store.activeStoreId])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.textColumn}>
          <div className={styles.title}>{`Welcome back, ${user.firstName}!`}</div>
          <div className={styles.subtitle}>Track, manage and forecast your customers and orders.</div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.leftPart}>
          <div className={styles.statistic}>
            <div className={styles.statisticBox}>
              <div className={styles.statisticTextColumn}>
                <div className={styles.statisticTitle}>Total Sales</div>
                <div className={styles.statisticSum}>{`$${(dashboard.sales / 100).toFixed(2)}`}</div>
                <div className={styles.statisticPeriod}>in the last 7 days</div>
              </div>
              <div className={styles.iconBox}>
                <div className={styles.icon} style={{ fontSize: '15px', color: '#5D4AC5' }}>
                  <ShoppingOutlined />
                </div>
              </div>
            </div>
            <div className={styles.statisticBox}>
              <div className={styles.statisticTextColumn}>
                <div className={styles.statisticTitle}>Profit</div>
                <div className={styles.statisticSum}>{`$${(dashboard.profit / 100).toFixed(2)}`}</div>
                <div className={styles.statisticPeriod}>in the last 7 days</div>
              </div>
              <div className={styles.iconBox}>
                <div className={styles.icon} style={{ fontSize: '15px', color: '#5D4AC5' }}>
                  <FundOutlined />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.productsBanner}>
            <div className={styles.productsBannerTextColumn}>
              <div className={styles.productsBannerTitle}>View all your products!</div>
              <div className={styles.productsBannerText}>
                Your product doesn’t need to be perfect. Just put it out there, and see if it sticks.
              </div>
              <div className={styles.productsBannerBtn} onClick={() => navigate('/products')}>
                <Button type='primary' size='large'>
                  View Products
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.infoItem}>
              <div className={styles.infoItemTextColumn}>
                <div className={styles.infoItemTitle}>Contact Store Manager!</div>
                <div className={styles.infoItemDescription}>If you have any issues just contact us and we’ll help you.</div>
                <a className={styles.infoItemBtn} href={`mailto:${settings.supportEmail}`}>
                  <Button type='dashed' size='large' icon={<MessageOutlined style={{ fontSize: '20px' }} />}>
                    Contact Support
                  </Button>
                </a>
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.infoItemTextColumn}>
                <div className={styles.infoItemTitle}>Check Open Orders!</div>
                <div className={styles.infoItemDescription}>Check your orders! You have X orders in the last 7 days.</div>
                <div className={styles.infoItemBtn} onClick={() => navigate('/orders')}>
                  <Button type='dashed' size='large' icon={<ShoppingCartOutlined style={{ fontSize: '20px' }} />}>
                    View All Orders
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.table}>
            <div className={styles.tableTitle}>Activities</div>
            <div className={styles.tableBody}>
              {dashboard.activities.length > 0 ? (
                dashboard.activities.map((item) => (
                  <div className={styles.tableItem} key={item.orderId}>
                    <div className={styles.itemInfo}>
                      <div className={styles.avatar}>
                        <Avatar.Group>
                          {item.imageUrls.map((image, index) => (
                            <Avatar key={index} size={40} src={imageLoader({ src: image, width: 40, height: 40 })} />
                          ))}
                        </Avatar.Group>
                      </div>
                      <div className={styles.infoItemTextColumn2}>
                        <div className={styles.infoItemTitle2}>
                          You’ve got new <span className={styles.purpleText}>{`Order #${item.orderId as string}`}</span>
                        </div>
                        <div
                          className={styles.infoItemLink}
                          onClick={async () => {
                            if (store.activeStoreId) {
                              await orderDetails.fetch(store.activeStoreId, parseInt(item.orderId))
                              setIsDetailsVisible(true)
                            }
                          }}
                        >
                          View order details
                          <ArrowRightOutlined style={{ marginLeft: '6px' }} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.itemTime}>{dayjs(item.date).fromNow()}</div>
                  </div>
                ))
              ) : (
                <div className={styles.emptyBlock}>
                  <img src={emptyImg} alt='Empty box' width={150} height={120} />
                  <div className={styles.emptyTitle}>No Orders Yet</div>
                  <div className={styles.emptyDescription}>
                    Your orders will show up here as they come in. For now,{' '}
                    <span className={styles.purpleLink} onClick={() => navigate('/')}>
                      customize your profile
                    </span>{' '}
                    to get sales.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <OrderDetails isVisible={isDetailsVisible} onClose={() => setIsDetailsVisible(false)} orderDetails={orderDetails} />
    </div>
  )
}

export default observer(Dashboard)
