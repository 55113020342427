import React from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import BankAccountCard from '../BankAccountCard'
import styles from './PaymentInfo.module.scss'
import { Empty } from 'components'
import PaymentAccountModal from '../PaymentAccountModal'

const PaymentInfo = () => {
  const [isCreateModalVisible, setIsCreateModalVisible] = React.useState<boolean>(false)

  const hasPaymentAccount = false

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTexts}>
          <div className={styles.headerTitle}>Payment methods</div>
          <div className={styles.headerSubtitle}>Change how you pay for commissions.</div>
        </div>
        {hasPaymentAccount && (
          <div onClick={() => setIsCreateModalVisible(true)}>
            <Button icon={<PlusOutlined />} size='large'>
              Add New Bank Account
            </Button>
          </div>
        )}
      </div>
      {!hasPaymentAccount && <Empty paymentMethod />}
      {hasPaymentAccount && (
        <div className={styles.content}>
          <BankAccountCard />
          <BankAccountCard />
        </div>
      )}
      <PaymentAccountModal isVisible={isCreateModalVisible} onClose={() => setIsCreateModalVisible(false)} />
    </div>
  )
}

export default PaymentInfo
