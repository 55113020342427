import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import emptyCloudImg from 'assets/emptyCloud.svg'
import emptyPaymentImg from 'assets/emptyPayments.svg'
import styles from './Empty.module.scss'
import { Button } from 'antd'
import { PaymentAccountModal } from 'features/payments/components'

type Props = {
  paymentMethod?: boolean
}

const Empty = ({ paymentMethod = false }: Props) => {
  const [isCreateModalVisible, setIsCreateModalVisible] = React.useState<boolean>(false)

  return (
    <div className={styles.container}>
      {!paymentMethod && (
        <>
          <img src={emptyCloudImg} alt='Empty' width={152} height={118} />
          <div className={styles.text}>No Data</div>
        </>
      )}
      {paymentMethod && (
        <>
          <img src={emptyPaymentImg} alt='Empty' width={152} height={118} />
          <div className={styles.emptyBoxTitle}>Add your payment method</div>
          <div className={styles.emptyBoxSubtitle}>
            Add your bank account as a payment method to be able to sell products on FanMadeFits
          </div>
          <div className={styles.btn} onClick={() => setIsCreateModalVisible(true)}>
            <Button type='primary' icon={<PlusOutlined />}>
              Add Your Bank Account
            </Button>
          </div>
        </>
      )}
      <PaymentAccountModal isVisible={isCreateModalVisible} onClose={() => setIsCreateModalVisible(false)} />
    </div>
  )
}

export default Empty
