import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import numeral from 'numeral'
import { Button, ConfigProvider, DatePicker, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
// import { PaymentMethod } from '@merchx-v2/shared-types/dist/constants/paymentMethods'
import { Empty, Pagination } from 'components'

import { store } from 'features/storeSettings/models'
import { payments } from 'features/payments/models'
import bankIcon from 'assets/bank.svg'
// import googlePayIcon from 'assets/googlePay.svg'
// import applePayIcon from 'assets/remove.svg'
// import paypalIcon from 'assets/paypal.svg'
import styles from './PaymentsList.module.scss'
import settings from 'shared/settings'
import { StorePayoutDto } from '@merchx-v2/shared-types'

type PresetItem = {
  label: string
  value: [Dayjs, Dayjs]
}

const presets: PresetItem[] = [
  { label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
  { label: 'Yesterday', value: [dayjs().add(-1, 'day').startOf('day'), dayjs().add(-1, 'd').endOf('day')] },
  { label: 'This week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
  { label: 'Last week', value: [dayjs().add(-1, 'week').startOf('week'), dayjs().add(-1, 'week').endOf('week')] },
  { label: 'This month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
  { label: 'Last month', value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')] },
  { label: 'This year', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
  { label: 'Last year', value: [dayjs().add(-1, 'year').startOf('year'), dayjs().add(-1, 'year').endOf('year')] }
]

// const fakeData = [
//   {
//     id: '1',
//     createdAt: '2022-09-27T18:20:06.616Z',
//     paymentPeriod: 'Dec 1, 2022 - Dec 31, 2022',
//     paymentMethod: 'CARD',
//     card: 'k****a@gmail.com',
//     amount: 20000
//   }
// ]

// const methods: Record<PaymentMethod, string> = {
//   CARD: bankIcon,
//   GOOGLE_PAY: googlePayIcon,
//   APPLE_PAY: applePayIcon,
//   PAYPAL: paypalIcon
// }

const columns: ColumnsType<StorePayoutDto> = [
  {
    title: <div className={styles.grayText}>ID</div>,
    dataIndex: 'id',
    width: '93px'
  },
  {
    title: <div className={styles.grayText}>Date</div>,
    dataIndex: 'processedAt',
    width: '189px',
    render: (value: Date) => <div className={styles.darkTextThin}>{dayjs(value).format('MM/DD/YYYY HH:mm:ss')}</div>
  },

  {
    title: <div className={styles.grayText}>Payment Period</div>,
    dataIndex: 'periodStart',
    width: '394px',
    render: (_value: string, record) => (
      <div className={styles.darkText}>
        {dayjs(record.periodStart).format('MM/DD/YYYY')} - {dayjs(record.periodEnd).format('MM/DD/YYYY')}
      </div>
    )
  },
  {
    title: <div className={styles.grayText}>Payment Method</div>,
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    width: '328px',
    render: (_text, record) => {
      return (
        <div className={styles.paymentMethod}>
          {/* <img src={methods[record.paymentMethod]} alt={methods[record.paymentMethod]} /> */}
          <img src={bankIcon} alt='' />
          <div className={styles.cardInfo}>{record.paymentMethod}</div>
        </div>
      )
    }
  },
  {
    title: <div className={styles.grayText}>Amount</div>,
    dataIndex: 'sum',
    key: 'sum',
    width: '130px',
    render: (value: number) => <div className={styles.darkText}>{numeral(value / 100).format('$0,0.00')}</div>
  }
]

const PaymentsList = () => {
  const [page, setPage] = useState<number>(1)
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'))
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('day'))

  useEffect(() => {
    store.fetch()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [store.id])

  useEffect(() => {
    if (store.status === 'success') {
      payments.fetchPayments(store.id, startDate?.toDate(), endDate?.toDate(), page)
    }
  }, [store.id, startDate, endDate, store.status, page])

  const customizeRenderEmpty = () => <Empty paymentMethod />

  const onRangeChange = (dates: null | Array<Dayjs | null>) => {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (dates && dates?.[0] !== null && dates[1] !== null) {
      setStartDate(dates[0])
      setEndDate(dates[1])
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.textColumn}>
          <div className={styles.title}>Payments</div>
          <div className={styles.subtitle}>Manage all payments for store here</div>
        </div>
        <div className={styles.btnGroup}>
          <DatePicker.RangePicker format='MMM D, YYYY' presets={presets} onChange={onRangeChange} defaultValue={presets[0].value} />
          <div className={styles.btn}>
            <Button
              type='primary'
              size='large'
              onClick={() => payments.fetchCSV(store.id, startDate?.toDate(), endDate?.toDate())}
              loading={payments.isLoading}
              icon={<DownloadOutlined style={{ fontSize: 20 }} />}
            >
              Export CSV
            </Button>
          </div>
        </div>
      </div>

      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <div className={styles.table}>
          <Table
            columns={columns}
            dataSource={payments.list}
            loading={store.isLoading || payments.isLoading}
            pagination={false}
            rowKey={(record) => record.id}
          />
          <Pagination
            currentPage={page}
            totalPages={payments.total / settings.defaultPageSize}
            onChange={(newPage) => {
              setPage(newPage)
            }}
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

export default observer(PaymentsList)
