import { makeAutoObservable } from 'mobx'
import { StoreProductsPageDto } from '@merchx-v2/shared-types/dist/dto/stores/store-products-page.dto'
import { StoreProductsPageItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-products-page-item.dto'
import axios from 'services/api'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'

type QueryResponse<Result extends string = 'storeProductsPage'> = GraphQLResponse<Result, StoreProductsPageDto>

const STORE_PRODUCTS = `
query storeProductsPage ($page: Int, $size: Int, $storeId: Int!, $searchText: String) {
  storeProductsPage (page: $page, size: $size, storeId: $storeId, searchText: $searchText) {
    items {
      id
      productType
      productName
      supplierName
      productType
      price
      imageUrl
      sortIndex
    }
    total
  }
}
`

class Products {
  total = 0
  list: StoreProductsPageItemDto[] = []
  status: RequestStatus = 'idle'
  error = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.list = []
    this.total = 0
    this.status = 'idle'
    this.error = ''
  }

  *fetchProducts(storeId: number, page?: number) {
    if (this.status === 'loading') return
    this.status = 'loading'

    try {
      const { data }: QueryResponse = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_PRODUCTS,
        variables: {
          storeId,
          page: page || 1,
          size: settings.defaultPageSize
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storeProductsPage) {
        throw new Error("Can't fetch store")
      }

      this.total = data.data.storeProductsPage.total
      this.list = data.data.storeProductsPage?.items || []

      this.status = 'success'
      this.error = ''
    } catch (err) {
      this.status = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.status === 'loading'
  }
}

export const products = new Products()
