import React from 'react'
import { v4 as uuid } from 'uuid'
import Table from 'antd/es/table'
import type { ColumnsType } from 'antd/es/table'
import { observer } from 'mobx-react-lite'
import styles from './OrderDetailsAddress.module.scss'
import { OrderDetails as OrderDetailsState } from 'features/orders/models'

type Props = {
  orderDetails: OrderDetailsState
}

const OrderDetailsAddress = ({ orderDetails }: Props) => {
  const columns: ColumnsType<any> = [
    {
      title: '',
      width: '120px',
      render: () => <div className={styles.current}>Current</div>
    },
    {
      title: 'Country',
      dataIndex: 'shippingAddressCountry',
      key: 'country'
    },
    {
      title: 'Address 1',
      dataIndex: 'shippingAddressAddress1',
      key: 'address'
    },
    {
      title: 'Address 2',
      dataIndex: 'shippingAddressAddress2',
      key: 'address2'
    },
    {
      title: 'City',
      dataIndex: 'shippingAddressCity',
      key: 'city'
    },
    {
      title: 'State',
      dataIndex: 'shippingAddressState',
      key: 'state'
    },
    {
      title: 'Zip Code',
      dataIndex: 'shippingAddressZipCode',
      key: 'zipCode'
    },
    {
      title: 'Action',
      key: 'action',
      width: '120px'
    }
  ]

  const sourceData = [
    {
      uid: uuid(),
      shippingAddressCountry: orderDetails.shippingAddressCountry,
      shippingAddressAddress1: orderDetails.shippingAddressAddress1,
      shippingAddressAddress2: orderDetails.shippingAddressAddress2,
      shippingAddressCity: orderDetails.shippingAddressCity,
      shippingAddressState: orderDetails.shippingAddressState,
      shippingAddressZipCode: orderDetails.shippingAddressZipCode
    }
  ]

  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        dataSource={sourceData}
        loading={orderDetails.isLoading}
        pagination={false}
        rowKey={(record) => record.uid}
      />
    </div>
  )
}

export default observer(OrderDetailsAddress)
