import { makeAutoObservable } from 'mobx'
import axios from 'services/api'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'
import { StoreOrdersPageDto } from '@merchx-v2/shared-types/dist/dto/stores/store-orders-page.dto'
import { StoreOrdersPageItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-orders-page-item.dto'

type QueryResponseFetchList<Result extends string = 'storeOrdersPage'> = GraphQLResponse<Result, StoreOrdersPageDto>
type QueryResponseFetchCSV<Result extends string = 'storeOrdersPageToCSV'> = GraphQLResponse<Result, string>

const STORE_ORDERS = `
query storeOrdersPage($page: Int, $size: Int, $storeId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  storeOrdersPage(page: $page, size: $size, storeId: $storeId, startDate: $startDate, endDate: $endDate) {
    total
    items {
      id
      date
      status
      customerFirstName
      customerLastName
      totalRevenue
      totalProfit
    }
  }
}
`

const STORE_ORDERS_CSV = `
query storeOrdersPageToCSV($storeId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  storeOrdersPageToCSV(storeId: $storeId, startDate: $startDate, endDate: $endDate)
}
`

class OrdersList {
  total = 0
  list: StoreOrdersPageItemDto[] = []
  requestStatus: RequestStatus = 'idle'
  error = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.list = []
    this.total = 0
    this.requestStatus = 'idle'
    this.error = ''
  }

  *fetch(storeId: number, startDate: Date, endDate: Date, page?: number) {
    if (this.requestStatus === 'loading') return
    this.requestStatus = 'loading'

    try {
      const { data }: QueryResponseFetchList = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_ORDERS,
        variables: {
          page: page || 1,
          size: settings.defaultPageSize,
          storeId,
          startDate,
          endDate
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storeOrdersPage) {
        throw new Error("Can't fetch store")
      }

      this.total = data.data.storeOrdersPage.total
      this.list = data.data.storeOrdersPage?.items || []

      this.requestStatus = 'success'
      this.error = ''
    } catch (err) {
      this.requestStatus = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  *fetchCSV(storeId: number, startDate: Date, endDate: Date) {
    if (this.requestStatus === 'loading') return
    this.requestStatus = 'loading'

    try {
      const { data }: QueryResponseFetchCSV = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_ORDERS_CSV,
        variables: {
          storeId,
          startDate,
          endDate
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storeOrdersPageToCSV) {
        throw new Error("Can't fetch store csv")
      }

      window.open(data.data.storeOrdersPageToCSV, '_blank')

      this.requestStatus = 'success'
      this.error = ''
    } catch (err) {
      this.requestStatus = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.requestStatus === 'loading'
  }
}

export const ordersList = new OrdersList()
