import { makeAutoObservable } from 'mobx'
import { PublicStoreDto } from '@merchx-v2/shared-types/dist/dto/storesPublic/public-store.dto'
import { PublicStoreFollowersPageDto } from '@merchx-v2/shared-types/dist/dto/storesPublic/public-store-followers-page.dto'
import { CreateStoreDto } from '@merchx-v2/shared-types/dist/dto/stores/create-store.dto'
import axios from 'services/api'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'

type QueryResponseFetch<Result extends string = 'customerStore'> = GraphQLResponse<Result, PublicStoreDto>
type QueryResponseSwitchStore<Result extends string = 'storeAdminSwitchStore'> = GraphQLResponse<Result, boolean>
type QueryResponseUpdate<Result extends string = 'updateStoreByOwner'> = GraphQLResponse<Result, boolean>

const UPDATE_STORE = `
  mutation updateStoreByOwner ($storeData: StoreInputForOwners!) {
    updateStoreByOwner(storeData: $storeData)
  }
`

const SWITCH_STORE = `
  mutation storeAdminSwitchStore ($storeId: Int!) {
    storeAdminSwitchStore(storeId: $storeId)
  }
`

const FETCH_STORE = `
  query CustomerStore ($storeId: Int) {
    customerStore (storeId: $storeId) {
      id
      name
      description
      supportEmail

      isActive
      
      mainImageUrl
      avatarImageUrl
      bannerImageUrl
      bannerLink
      
      hasCustomSeo
      seoUrl
      seoTitle
      seoDescription
      seoKeywords
      
      facebookLink
      twitterLink
      instagramLink
      linkedInLink
      pinterestLink
      tiktokLink
      youtubeLink
      threadsLink
      location
      
      balance
      pendingBalance

      createdAt
      updatedAt

      followers {
        total
        items {
          email
          firstName
          lastName
          avatarUrl
          startedAt
        }
      }
    }
  }
  `

export class Store {
  status: RequestStatus = 'idle'
  error = ''
  activeStoreId: number | null = null

  id = -1
  name = ''
  description = ''
  isActive = false
  supportEmail = ''
  mainImageUrl = ''
  avatarImageUrl = ''
  bannerImageUrl = ''
  bannerLink = ''
  hasCustomSeo = false
  seoUrl = ''
  seoTitle = ''
  seoDescription = ''
  seoKeywords = ''
  facebookLink = ''
  twitterLink = ''
  instagramLink = ''
  linkedInLink = ''
  pinterestLink = ''
  tiktokLink = ''
  youtubeLink = ''
  threadsLink = ''
  location = ''
  balance = 0
  pendingBalance = 0
  ownerFirstName = ''
  ownerLastName = ''
  ownerEmail = ''
  ownerAvatarUrl = ''
  followers: PublicStoreFollowersPageDto = { total: 0, items: [] }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    this.fetch()
  }

  reset() {
    this.status = 'idle'
    this.error = ''
    this.activeStoreId = null
    this.id = -1
    this.name = ''
    this.description = ''
    this.isActive = false
    this.supportEmail = ''
    this.mainImageUrl = ''
    this.avatarImageUrl = ''
    this.bannerImageUrl = ''
    this.bannerLink = ''
    this.hasCustomSeo = false
    this.seoUrl = ''
    this.seoTitle = ''
    this.seoDescription = ''
    this.seoKeywords = ''
    this.facebookLink = ''
    this.twitterLink = ''
    this.instagramLink = ''
    this.linkedInLink = ''
    this.pinterestLink = ''
    this.tiktokLink = ''
    this.youtubeLink = ''
    this.threadsLink = ''
    this.location = ''
    this.balance = 0
    this.pendingBalance = 0
    this.ownerFirstName = ''
    this.ownerLastName = ''
    this.ownerEmail = ''
    this.ownerAvatarUrl = ''
  }

  *setActiveStoreId(storeId: number) {
    this.activeStoreId = storeId
    yield this.switchStore()
    yield this.fetch()
  }

  *switchStore() {
    try {
      const {
        data: { data, errors }
      }: QueryResponseSwitchStore = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: SWITCH_STORE,
        variables: {
          storeId: this.activeStoreId
        }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      if (!data.storeAdminSwitchStore) {
        throw new Error("Can't fetch store")
      }
    } catch (exception) {
      this.status = 'error'
      notification({ text: extractErrorInfo(exception) })
    }
  }

  *fetch() {
    if ((this.status === 'loading' || this.status === 'success') && this.id === this.activeStoreId) return
    this.status = 'loading'
    try {
      const {
        data: { data, errors }
      }: QueryResponseFetch = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: FETCH_STORE,
        variables: {
          storeId: this.activeStoreId
        }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      if (!data.customerStore) {
        throw new Error("Can't fetch store")
      }

      this.status = 'success'
      this.error = ''

      this.activeStoreId = data.customerStore.id

      this.id = data.customerStore.id
      this.name = data.customerStore.name
      this.description = data.customerStore.description
      this.isActive = data.customerStore.isActive
      this.supportEmail = data.customerStore.supportEmail
      this.mainImageUrl = data.customerStore.mainImageUrl
      this.avatarImageUrl = data.customerStore.avatarImageUrl
      this.bannerImageUrl = data.customerStore.bannerImageUrl
      this.bannerLink = data.customerStore.bannerLink
      this.hasCustomSeo = data.customerStore.hasCustomSeo
      this.seoUrl = data.customerStore.seoUrl
      this.seoTitle = data.customerStore.seoTitle
      this.seoDescription = data.customerStore.seoDescription
      this.seoKeywords = data.customerStore.seoKeywords
      this.facebookLink = data.customerStore.facebookLink
      this.twitterLink = data.customerStore.twitterLink
      this.instagramLink = data.customerStore.instagramLink
      this.linkedInLink = data.customerStore.linkedInLink
      this.pinterestLink = data.customerStore.pinterestLink
      this.tiktokLink = data.customerStore.tiktokLink
      this.youtubeLink = data.customerStore.youtubeLink
      this.threadsLink = data.customerStore.threadsLink
      this.location = data.customerStore.location
      this.balance = data.customerStore.balance || 0
      this.pendingBalance = data.customerStore.pendingBalance || 0
      this.ownerFirstName = data.customerStore.ownerFirstName ?? ''
      this.ownerLastName = data.customerStore.ownerLastName ?? ''
      this.ownerEmail = data.customerStore.ownerEmail ?? ''
      this.ownerAvatarUrl = data.customerStore.ownerAvatarUrl ?? ''
    } catch (err) {
      this.status = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  *update(store: CreateStoreDto) {
    if (this.isLoading) return
    this.status = 'loading'

    try {
      const {
        data: { data, errors }
      }: QueryResponseUpdate = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: UPDATE_STORE,
        variables: {
          storeId: this.id,
          storeData: {
            ...store,
            seoUrl: this.seoUrl,
            seoDescription: this.seoDescription,
            seoKeywords: this.seoKeywords,
            seoTitle: this.seoTitle
          }
        }
      })

      guardFromErrors(errors)

      if (!data) {
        throw new Error('Response body is empty!')
      }

      if (!data.updateStoreByOwner) {
        throw new Error("Can't update store")
      }

      this.status = 'success'
      this.error = ''

      this.name = store.name
      this.description = store.description
      this.isActive = store.isActive
      this.supportEmail = store.supportEmail
      this.description = store.description
      this.bannerLink = store.bannerLink
      this.facebookLink = store.facebookLink
      this.instagramLink = store.instagramLink
      this.twitterLink = store.twitterLink
      this.linkedInLink = store.linkedInLink
      this.tiktokLink = store.tiktokLink
      this.youtubeLink = store.youtubeLink
      this.threadsLink = store.threadsLink
      this.location = store.location
      notification({ text: 'Successfully!', type: 'success' })
    } catch (err) {
      this.status = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.status === 'loading'
  }
}

export const store = new Store()
