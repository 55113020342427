import { StoreInviteStatusDto } from '@merchx-v2/shared-types/dist/dto/stores/store-invite-status.dto'
import { makeAutoObservable } from 'mobx'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import api from 'services/api'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'

type CustomerStoreInviteStatusQueryResponse<Result extends string = 'storeInviteStatus'> = GraphQLResponse<Result, StoreInviteStatusDto>
type NonExistUserConfirmInviteQueryResponse<Result extends string = 'nonExistUserConfirmInviteToStore'> = GraphQLResponse<Result, boolean>
type ExistUserConfirmInviteQueryResponse<Result extends string = 'existUserConfirmInviteToStore'> = GraphQLResponse<Result, boolean>
type DeclineStoreInviteQueryResponse<Result extends string = 'declineStoreInvite'> = GraphQLResponse<Result, boolean>

const CUSTOMER_INVITE_STATUS = `
query StoreInviteStatus($token: String!) {
  storeInviteStatus(token: $token) {
    email
    storeName
    status
  }
}
`

const NON_EXIST_USER_CONFIRM_INVITE = `
mutation NonExistUserConfirmInviteToStore($token: String!, $password: String!, $firstName: String, $lastName: String, $username: String) {
  nonExistUserConfirmInviteToStore(token: $token, password: $password, firstName: $firstName, lastName: $lastName, username: $username)
}
`

const EXIST_USER_CONFIRM_INVITE = `
mutation ExistUserConfirmInviteToStore($token: String!) {
  existUserConfirmInviteToStore(token: $token)
}
`

const DECLINE_STORE_INVITE = `
mutation DeclineStoreInvite($token: String!) {
  declineStoreInvite(token: $token)
}
`

export class Invite {
  inviteStatus: StoreInviteStatusDto['status'] | null = null
  email = ''
  storeName = ''

  status: RequestStatus = 'idle'
  error = ''

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetch(token: string) {
    if (this.isLoading) return
    this.status = 'loading'

    try {
      const { data }: CustomerStoreInviteStatusQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: CUSTOMER_INVITE_STATUS,
        variables: { token }
      })

      guardFromErrors(data?.errors)

      if (!data?.data) {
        throw new Error('Network error!')
      }

      const { storeInviteStatus } = data.data

      this.inviteStatus = storeInviteStatus.status
      this.email = storeInviteStatus.email
      this.storeName = storeInviteStatus.storeName

      this.status = 'success'
      this.error = ''
      return true
    } catch (err) {
      this.status = 'error'
      this.error = extractErrorInfo(err)
      notification({ text: extractErrorInfo(err) })
    }
  }

  *nonExistUserConfirm(token: string, password: string) {
    if (this.isLoading) return
    this.status = 'loading'

    try {
      const { data }: NonExistUserConfirmInviteQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: NON_EXIST_USER_CONFIRM_INVITE,
        variables: { token, password }
      })

      guardFromErrors(data?.errors)

      if (!data?.data) {
        throw new Error('Response body is empty!')
      }

      const { nonExistUserConfirmInviteToStore } = data.data

      this.status = 'success'
      this.error = ''
      return nonExistUserConfirmInviteToStore
    } catch (err) {
      this.status = 'error'
      this.error = extractErrorInfo(err)
      notification({ text: extractErrorInfo(err) })
    }
  }

  *existUserConfirm(token: string) {
    if (this.isLoading) return
    this.status = 'loading'

    try {
      const { data }: ExistUserConfirmInviteQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: EXIST_USER_CONFIRM_INVITE,
        variables: { token }
      })

      guardFromErrors(data?.errors)

      if (!data?.data) {
        throw new Error('Network error!')
      }

      this.status = 'success'
      this.error = ''
      return true
    } catch (err) {
      this.status = 'error'
      this.error = extractErrorInfo(err)
      notification({ text: extractErrorInfo(err) })
    }
  }

  *decline(token: string) {
    if (this.isLoading) return
    this.status = 'loading'

    try {
      const { data }: DeclineStoreInviteQueryResponse = yield api.post(`${settings.backendUrl}/graphql`, {
        query: DECLINE_STORE_INVITE,
        variables: { token }
      })

      guardFromErrors(data?.errors)

      if (!data?.data) {
        throw new Error('Network error!')
      }

      const { declineStoreInvite } = data.data

      this.status = 'success'
      this.error = ''
      return declineStoreInvite
    } catch (err) {
      this.status = 'error'
      this.error = extractErrorInfo(err)
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.status === 'loading'
  }
}

export const invite = new Invite()
