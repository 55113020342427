import { notification as notifyFunc } from 'antd'

type PropsType = {
  text?: string
  description?: string
  type?: 'error' | 'info' | 'success' | 'warning'
}

export const notification = (props: PropsType) => {
  const { text, type = 'error', description } = props

  if (text) {
    if (type === 'success') {
      notifyFunc.success({
        key: text,
        message: text,
        description,
        placement: 'top',
        style: {
          color: '#ffffff',
          borderRadius: '5px',
          boxShadow: '-10px 10px 50px rgba(0, 0, 0, 0.1)'
        }
      })
    }
    if (type === 'info') {
      notifyFunc.info({
        key: text,
        message: text,
        description,
        placement: 'top',
        style: {
          borderRadius: '5px',
          boxShadow: '-10px 10px 50px rgba(0, 0, 0, 0.1)'
        }
      })
    }
    if (type === 'warning') {
      notifyFunc.warning({
        key: text,
        message: text,
        description,
        placement: 'top',
        style: {
          color: '#ffffff',
          borderRadius: '5px',
          boxShadow: '-10px 10px 50px rgba(0, 0, 0, 0.1)'
        }
      })
    }
    if (type === 'error') {
      notifyFunc.error({
        key: text,
        message: text,
        description,
        placement: 'top',
        style: {
          color: '#ffffff',
          borderRadius: '5px',
          boxShadow: '-10px 10px 50px rgba(0, 0, 0, 0.1)'
        }
      })
    }
  }
}
