import { settings } from 'shared'

type ImageLoaderProps = {
  src: string
  width: number
  height?: number
  quality?: number
  blur?: number
}

export const imageLoader = ({ src, width, height, quality, blur }: ImageLoaderProps) => {
  let link = `${settings.imageResizerHost}/imageResizer?key=${src}/${width}`

  if (height) {
    link += `x${height}`
  }

  if (quality) {
    link += `&quality=${quality}`
  }

  if (blur) {
    link += `&blur=${blur}`
  }

  return link
}
