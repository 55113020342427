import React from 'react'
import querystringify from 'querystringify'
import { Navigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { user } from 'features/user/models'
import AppRoutes from './AppRoutes'

const AnonymousRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation()

  if (user.isLoggedIn) {
    const { callbackUrl } = querystringify.parse(location.search) as any

    if (callbackUrl) {
      return <Navigate to={callbackUrl} />
    } else {
      return <Navigate to={AppRoutes.home} />
    }
  }

  return <>{children}</>
}

export default observer(AnonymousRoute)
