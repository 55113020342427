import { makeAutoObservable } from 'mobx'
import axios from 'services/api'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'
import { OrderStatus } from '@merchx-v2/shared-types/dist/constants/orderStatus'
import { StoreOrderDto } from '@merchx-v2/shared-types/dist/dto/stores/store-order.dto'
import { StoreOrderItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-order-item.dto'

type QueryResponseFetchOrder<Result extends string = 'storeOrder'> = GraphQLResponse<Result, StoreOrderDto>

const STORE_ORDER = `
  query storeOrder($storeId: Int!, $orderId: Int!) {
   storeOrder(storeId: $storeId, orderId: $orderId) {
      id
      date
      status
      customerFirstName
      customerLastName
      promocode
      paymentMethod

      shippingAddressCountry
      shippingAddressZipCode
      shippingAddressState
      shippingAddressCity
      shippingAddressAddress1
      shippingAddressAddress2

      items {
          id
          productName
          productVariant
          productSku
          quantity
          price
          discount
          supplierCost
          total
          profit
      }
      refunded
      subtotal
      totalProfit
    }
 }`

export class OrderDetails {
  requestStatus: RequestStatus = 'idle'
  error = ''

  id = -1
  date = new Date()
  status: OrderStatus = 'NEW'
  customerFirstName = ''
  customerLastName = ''
  promocode = ''
  paymentMethod = ''

  shippingAddressCountry = ''
  shippingAddressZipCode = ''
  shippingAddressState = ''
  shippingAddressCity = ''
  shippingAddressAddress1 = ''
  shippingAddressAddress2 = ''
  orderItems: StoreOrderItemDto[] = []
  refunded = 0
  subtotal = 0
  totalProfit = 0

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.requestStatus = 'idle'
    this.error = ''

    this.id = -1
    this.date = new Date()
    this.status = 'NEW'
    this.customerFirstName = ''
    this.customerLastName = ''
    this.promocode = ''
    this.paymentMethod = ''

    this.shippingAddressCountry = ''
    this.shippingAddressZipCode = ''
    this.shippingAddressState = ''
    this.shippingAddressCity = ''
    this.shippingAddressAddress1 = ''
    this.shippingAddressAddress2 = ''
    this.orderItems = []
    this.refunded = 0
    this.subtotal = 0
    this.totalProfit = 0
  }

  *fetch(storeId: number, orderId: number) {
    if (this.requestStatus === 'loading') return
    this.requestStatus = 'loading'

    try {
      const { data }: QueryResponseFetchOrder = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_ORDER,
        variables: {
          storeId,
          orderId
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storeOrder) {
        throw new Error("Can't fetch store")
      }

      this.id = data.data.storeOrder.id
      this.date = data.data.storeOrder.date
      this.status = data.data.storeOrder.status
      this.customerFirstName = data.data.storeOrder.customerFirstName
      this.customerLastName = data.data.storeOrder.customerLastName
      this.promocode = data.data.storeOrder.promocode
      this.paymentMethod = data.data.storeOrder.paymentMethod

      this.shippingAddressCountry = data.data.storeOrder.shippingAddressCountry
      this.shippingAddressZipCode = data.data.storeOrder.shippingAddressZipCode
      this.shippingAddressState = data.data.storeOrder.shippingAddressState
      this.shippingAddressCity = data.data.storeOrder.shippingAddressCity
      this.shippingAddressAddress1 = data.data.storeOrder.shippingAddressAddress1
      this.shippingAddressAddress2 = data.data.storeOrder.shippingAddressAddress2
      this.orderItems = data.data.storeOrder.items
      this.refunded = data.data.storeOrder.refunded
      this.subtotal = data.data.storeOrder.subtotal
      this.totalProfit = data.data.storeOrder.totalProfit

      this.requestStatus = 'success'
      this.error = ''
    } catch (err) {
      this.requestStatus = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.requestStatus === 'loading'
  }
}

export const orderDetails = new OrderDetails()
