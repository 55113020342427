import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { transformUppercase } from 'helpers'
import { settings } from 'shared'
import { Pagination } from 'components'

import { StoreProductsPageItemDto } from '@merchx-v2/shared-types/dist/dto/stores/store-products-page-item.dto'
import { products } from 'features/products/models'
import { store } from 'features/storeSettings/models'
import styles from './ProductsList.module.scss'
import { imageLoader } from 'helpers/image-loader'

const columns: ColumnsType<StoreProductsPageItemDto> = [
  {
    title: <div className={styles.grayText}>ID</div>,
    dataIndex: 'id',
    width: '93px'
  },
  {
    title: <div className={styles.grayText}>Product</div>,
    dataIndex: 'imageUrl',
    render: (imageUrl: string) => (imageUrl ? <img src={imageLoader({ src: imageUrl, width: 40, height: 40 })} alt={imageUrl} /> : ''),
    width: '93px'
  },
  {
    title: <div className={styles.grayText}>Product Name</div>,
    dataIndex: 'productName'
  },
  {
    title: <div className={styles.grayText}>Supplier</div>,
    dataIndex: 'supplierName',
    width: '150px',
    render: (value: string) => <div className={styles.grayText}>{value}</div>
  },
  {
    title: <div className={styles.grayText}>Product Type</div>,
    dataIndex: 'productType',
    width: '150px',
    render: (value: string) => <div className={styles.grayText}>{transformUppercase(value)}</div>
  },
  {
    title: <div className={styles.grayText}>Price</div>,
    dataIndex: 'price',
    key: 'price',
    width: '150px',
    render: (value: number) => `$${(value / 100).toFixed(2)}`
  }
  // {
  //   title: <div className={styles.grayText}>Profit</div>,
  //   dataIndex: 'profit',
  //   key: 'profit',
  //   width: '150px',
  //   render: (value: number) => <div className={styles.profit}>${(value / 100).toFixed(2)}</div>
  // },
]

const ProductsPage: React.FC = () => {
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    store.fetch()
  }, [])

  useEffect(() => {
    if (store.status === 'success') {
      products.fetchProducts(store.id, page)
    }
  }, [store.status, page])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.textColumn}>
          <div className={styles.title}>Products</div>
          <div className={styles.subtitle}>Manage all products for store here</div>
        </div>
      </div>
      <div className={styles.productsListTable}>
        <Table
          columns={columns}
          dataSource={products.list}
          loading={store.isLoading || products.isLoading}
          pagination={false}
          rowKey={(record) => record.id}
        />
        <Pagination
          currentPage={page}
          totalPages={Math.ceil(products.total / settings.defaultPageSize)}
          onChange={(newPage) => {
            setPage(newPage)
          }}
        />
      </div>
    </div>
  )
}

export default observer(ProductsPage)
