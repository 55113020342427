import React from 'react'
import { Modal } from 'antd'
import storeLogoImg from 'assets/storeLogo.png'
import styles from './StoreLogoModal.module.scss'

type Props = {
  isVisible: boolean
  onClose: () => void
}

const StoreLogoModal = ({ isVisible, onClose }: Props) => {
  return (
    <Modal title='Logos: How it works' open={isVisible} footer={null} width={572} onCancel={onClose}>
      <div className={styles.container}>
        <img src={storeLogoImg} alt='Logo place' className={styles.logo} />
        <div className={styles.textSection}>
          <p className={styles.text}>
            Uploading a <span className={styles.textStrong}>logo</span> for your store or brand is an absolute must if you want potential
            customers to know who you are and what your business stands for. The logo will emphasize your uniqueness and be remembered by
            your fans.
          </p>
          <br /> This image will be displayed on your Store Page.
        </div>
      </div>
    </Modal>
  )
}

export default StoreLogoModal
