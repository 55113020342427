import React from 'react'
import { Layout as AntdLayout } from 'antd'
import SiderMenu from '../SiderMenu'
import styles from './Layout.module.scss'

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AntdLayout hasSider className={styles.layout}>
      <AntdLayout.Sider className={styles.sider} width={242}>
        <SiderMenu />
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Content className={styles.content}>{children}</AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
