import { makeAutoObservable } from 'mobx'
import axios from 'services/api'
import { notification, extractErrorInfo, guardFromErrors } from 'shared'
import settings from 'shared/settings'
import { GraphQLResponse } from 'shared/types'
import { StoreDashboardDto, StoreDashboardItemDto } from '@merchx-v2/shared-types'

type QueryResponseFetchDashboard<Result extends string = 'storeDashboard'> = GraphQLResponse<Result, StoreDashboardDto>

const STORE_DASHBOARD = `
query storeDashboard($storeId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  storeDashboard(storeId: $storeId, startDate: $startDate, endDate: $endDate) {
      sales
      profit
      activities {
          orderId
          date
          imageUrls
      }
  }
}
 `

export class Dashboard {
  requestStatus: RequestStatus = 'idle'
  error = ''

  sales = 0
  profit = 0

  activities: StoreDashboardItemDto[] = []

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.requestStatus = 'idle'
    this.error = ''

    this.sales = 0
    this.profit = 0
  }

  *fetch(storeId: number) {
    if (this.requestStatus === 'loading') return
    this.requestStatus = 'loading'

    try {
      const { data }: QueryResponseFetchDashboard = yield axios.post(`${settings.backendUrl}/graphql`, {
        query: STORE_DASHBOARD,
        variables: {
          storeId,
          startDate: new Date(Date.now() - 7 * 86_400_000),
          endDate: new Date()
        }
      })

      guardFromErrors(data?.errors)

      if (!data.data) {
        throw new Error('Network error!')
      }

      if (!data.data.storeDashboard) {
        throw new Error("Can't fetch dashboard")
      }

      this.sales = data.data.storeDashboard.sales
      this.profit = data.data.storeDashboard.profit
      this.activities = data.data.storeDashboard.activities

      this.requestStatus = 'success'
      this.error = ''
    } catch (err) {
      this.requestStatus = 'error'
      notification({ text: extractErrorInfo(err) })
    }
  }

  get isLoading() {
    return this.requestStatus === 'loading'
  }
}

export const dashboard = new Dashboard()
