import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfirmInvite, Login, ResetPassword } from 'features/user/components'
import { StoreSettings } from 'features/storeSettings/components'
import { ProductsList } from 'features/products/components'
import { OrdersList } from 'features/orders/components'
import { PaymentsPage } from 'features/payments/components'
import { Dashboard } from 'features/dashboard/components'
import AppRoutes from './AppRoutes'
import PrivateRoute from './PrivateRoute'
import AnonymousRoute from './AnonymousRoute'

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={AppRoutes.home}
          element={
            <PrivateRoute>
              <StoreSettings />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.products}
          element={
            <PrivateRoute>
              <ProductsList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.orders}
          element={
            <PrivateRoute>
              <OrdersList />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.payments}
          element={
            <PrivateRoute>
              <PaymentsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.login}
          element={
            <AnonymousRoute>
              <Login />
            </AnonymousRoute>
          }
        />
        <Route
          path={AppRoutes.resetPassword}
          element={
            <AnonymousRoute>
              <ResetPassword />
            </AnonymousRoute>
          }
        />
        <Route path={AppRoutes.confirmInvite} element={<ConfirmInvite />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
