import React, { useState } from 'react'
import { Button, Modal, Tag } from 'antd'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { OrderStatus } from '@merchx-v2/shared-types/dist/constants/orderStatus'
import { transformUppercase } from 'helpers'
import { Spinner } from 'components'
import OrderDetailsProducts from '../OrderDetailsProducts'
import OrderDetailsAddress from '../OrderDetailsAddress'
import { OrderDetails as OrderDetailsState } from 'features/orders/models'
import styles from './OrderDetails.module.scss'

type Props = {
  orderDetails: OrderDetailsState
  isVisible: boolean
  onClose: () => void
}

const tagColors: Record<OrderStatus, string> = {
  PENDING: 'yellow',
  HOLD: 'yellow',
  NEW: 'green',
  OPENED: 'green',
  DISPATCHED: 'green',
  SHIPPING: 'green',
  COMPLETED: 'green',
  FAILED: 'red',
  CANCELED: 'red',
  CANCELLING: 'red'
}

type LoaderProps = {
  isLoading: boolean
}

const LoaderComponent: React.FC<React.PropsWithChildren<LoaderProps>> = ({ children, isLoading }) => {
  if (isLoading) return <Spinner className={styles.spinner} />

  return <>{children}</>
}

const OrderDetails = ({ orderDetails, isVisible, onClose }: Props) => {
  const [selectedTab, setSelectedTab] = useState<'Products' | 'Address'>('Products')

  return (
    <Modal title='Order Details' onCancel={onClose} open={isVisible} confirmLoading width='85%' footer={null}>
      <div className={styles.info}>
        <div className={styles.infoRow}>
          <div className={styles.left}>
            <div className={styles.infoItemTitle}>Order ID</div>
            <LoaderComponent isLoading={orderDetails.isLoading}>
              <div className={styles.infoItemValue}>
                <span className={styles.id}>{orderDetails.id}</span>
                <Tag color={tagColors[orderDetails.status]}>
                  <span className={styles.dot} data-color={tagColors[orderDetails.status]} />
                  {transformUppercase(orderDetails.status)}
                </Tag>
              </div>
            </LoaderComponent>
          </div>
          <div className={styles.right}>
            <div className={styles.infoItemTitle}>Payment Method</div>
            <LoaderComponent isLoading={orderDetails.isLoading}>
              <div className={styles.infoItemValue}>{orderDetails.paymentMethod}</div>
            </LoaderComponent>
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.left}>
            <div className={styles.infoItemTitle}>Date Added</div>
            <LoaderComponent isLoading={orderDetails.isLoading}>
              <div className={styles.infoItemValue}>{dayjs(orderDetails.date).format('MM/DD/YYYY HH:mm:ss')}</div>
            </LoaderComponent>
          </div>
          <div className={styles.right}>
            <div className={styles.infoItemTitle}>Promocode</div>
            <LoaderComponent isLoading={orderDetails.isLoading}>
              <div className={styles.infoItemValue}>{orderDetails.promocode ? orderDetails.promocode : 'Not applied'}</div>
            </LoaderComponent>
          </div>
        </div>

        <div className={styles.infoRow}>
          <div className={styles.left}>
            <div className={styles.infoItemTitle}>Customer</div>
            <LoaderComponent isLoading={orderDetails.isLoading}>
              <div className={styles.infoItemValue}>{`${orderDetails.customerFirstName as string} ${
                orderDetails.customerLastName as string
              }`}</div>{' '}
            </LoaderComponent>
          </div>
          <div className={styles.right}>
            <div className={styles.infoItemTitle}>Status</div>
            <LoaderComponent isLoading={orderDetails.isLoading}>
              <div className={styles.infoItemValue}>{orderDetails.status}</div>
            </LoaderComponent>
          </div>
        </div>
      </div>
      <div className={styles.tabs}>
        <div
          className={selectedTab === 'Products' ? styles.tabProductsSelected : styles.tabProducts}
          onClick={() => setSelectedTab('Products')}
        >
          Products
        </div>
        <div
          className={selectedTab === 'Address' ? styles.tabAddressSelected : styles.tabAddress}
          onClick={() => setSelectedTab('Address')}
        >
          Shipping address
        </div>
      </div>
      {selectedTab === 'Products' && <OrderDetailsProducts orderDetails={orderDetails} />}
      {selectedTab === 'Address' && <OrderDetailsAddress orderDetails={orderDetails} />}
      <div className={styles.footer}>
        <div className={styles.btn}>
          <Button type='primary' onClick={onClose}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default observer(OrderDetails)
